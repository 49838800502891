/* html {
  cursor: none;
} */

button {
  cursor: pointer;
}

/* a {
  cursor: none;
} */
